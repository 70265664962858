
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import FormMixin from "@/mixins/Crud/FormMixin";
import IDomain from "@/interfaces/IDomain";
import DefaultForm from "@/components/forms/DefaultForm/DefaultForm.vue";
import SaveButton from "@/components/buttons/table_actions/SaveButton.vue";
import { mixins } from "vue-class-component";
import { UserStoreMixin } from "@/mixins/Stores/UserStoreMixin";
import AppConfig from "@/config/AppConfig";
import IUser from "@/mixins/interface/IUser";
import ICustomer from "@/interfaces/ICustomer";
import { CustomerStoreMixin } from "@/mixins/Stores/CustomerStoreMixin";
import { Customer } from "@/config/Modules";

const DomainModule = namespace("domain");

@Component({
  components: { SaveButton, DefaultForm },
  mixins: [FormMixin],
})
export default class DomainForm extends mixins(
  CustomerStoreMixin,
  UserStoreMixin
) {
  protected name = "DomainForm";

  @DomainModule.Action("load")
  protected loadDomainsAction: any;

  @DomainModule.Getter("getIsLoading")
  protected isLoading: any;

  @DomainModule.Getter("getIsSaving")
  protected isSaving: any;

  @DomainModule.Getter("getValidationErrors")
  protected getValidationErrors: any;

  @DomainModule.Getter("getError")
  protected getError: any;

  @Prop({ required: false, default: "create" })
  public mode!: string;

  @Prop({ required: false, default: null })
  public data!: IDomain;

  protected id?: number;
  protected url = "";
  protected contactEmail = "";
  protected contactName = "";

  protected customerName = "";

  protected customerId: number | null = null;

  protected webpageDescription = "";
  protected responsibleUserId: number | null = null;
  protected responsibleName: string | null = null;
  protected autoRenewal = false;
  protected createdAt?: Date | string;
  protected updatedAt?: Date | string;
  protected lastCheckAt?: Date | string | null;
  protected lastValidAt?: Date | string | null;
  protected sslCertificate: any = {};

  protected filteredResponsibleUser: Array<IUser> = [];
  protected selectedResponsibleUser: Array<IUser> = [];

  @Watch("data", { immediate: true, deep: true })
  protected onDataChanged(data: IDomain) {
    if (data) {
      this.setFormValues(data);
    }
  }

  @Watch("responsibleUserId", { immediate: true })
  protected onResponsibleUserIdChanged(
    responsibleUserId: number | null,
    oldResponsibleUserId: number | null
  ) {
    if (responsibleUserId && this.getUserList && this.getUserList.length) {
      this.responsibleName = this.getUserList.find((user: IUser) => {
        return user.id === Number(responsibleUserId);
      }).name;
    }

    if (
      responsibleUserId === null &&
      this.getUserList &&
      this.getUserList.length &&
      this.responsibleName !== ""
    ) {
      const responsibleName = this.getUserList.find((user: IUser) => {
        return user.id === Number(oldResponsibleUserId);
      })?.name;
      if (responsibleName && this.responsibleName === responsibleName) {
        this.responsibleName = null;
      }
    }
  }

  protected onCustomerSelect(customer: ICustomer): void {
    if (customer) {
      this.customerId = Number(customer.id);
    }
  }

  protected get customers(): Array<ICustomer> {
    return this.getCustomerList;
  }

  protected getFilteredResponsibleUsers(search: string) {
    const users: Array<IUser> = this.getUserList ?? [];
    this.filteredResponsibleUser = users.filter((user: IUser) => {
      return (
        user.name.toString().toLowerCase().indexOf(search.toLowerCase()) > 0 ||
        user.email.toString().toLowerCase().indexOf(search.toLowerCase()) > 0
      );
    });
  }

  public getFormValues(): IDomain {
    let domain: IDomain = {
      url: this.url,
      contact_email: this.contactEmail,
      contact_name: this.contactName,
      customer_name: this.customerName,
      customer_id: this.customerId,
      webpage_description: this.webpageDescription,
      responsible_name: this.responsibleName,
      responsible_user_id: this.responsibleUserId,
      auto_renewal: this.autoRenewal,
      last_check_at: this.lastCheckAt,
      last_valid_at: this.lastValidAt,
      ssl_certificate: this.sslCertificate,
    };
    if (this.data && this.data.id) {
      domain.id = this.id;
    }
    return domain;
  }

  protected setFormValues(data: IDomain): void {
    console.log("set form values ..", data);
    if (data) {
      this.id = data.id;
      this.url = data.url;
      this.contactEmail = data.contact_email;
      this.autoRenewal = Boolean(data.auto_renewal);
      this.createdAt = data.created_at;
      this.updatedAt = data.updated_at;
      this.lastCheckAt = data.last_check_at;
      this.lastValidAt = data.last_valid_at;
      this.sslCertificate = data.ssl_certificate;
      this.contactName = data.contact_name;
      this.responsibleName = data.responsible_name;
      this.responsibleUserId = data.responsible_user_id;
      this.customerName = data.customer?.customer ?? "";
      this.customerId = data.customer?.id ?? null;
      this.webpageDescription = data.webpage_description;
    }
  }

  protected init() {
    this.findAllCustomerItemsAction({
      resource: Customer.resource,
    });
    this.findAllUserItemsAction({
      resource: AppConfig.USRE_RESOURCE,
    });
  }
}
