var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mt-3"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-three-fifths"},[_c('DefaultForm',{attrs:{"is-saving":_vm.isSaving,"mode":_vm.mode},on:{"submit":_vm.onSubmit,"abort":_vm.onAbort},scopedSlots:_vm._u([{key:"default",fn:function(formSettings){return [_c('div',[_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.domain.customer_label')),"type":_vm.getValidationErrors && _vm.getValidationErrors.customer_id
                  ? 'is-danger'
                  : '',"message":_vm.getValidationErrors && _vm.getValidationErrors.customer_id
                  ? _vm.getValidationErrors.customer_id
                  : ''}},[_c('b-autocomplete',{attrs:{"placeholder":_vm.$tc('general.customer', 1),"keep-first":false,"open-on-focus":true,"data":_vm.customers,"field":"customer","clearable":true,"aria-required":true,"icon":"fas fa-copyright","required":""},on:{"select":_vm.onCustomerSelect},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('router-link',{attrs:{"to":{
                      name: 'BaseDataCustomerCreate',
                      query: {
                        redirectTo: _vm.$route.fullPath,
                      },
                    },"custom":""}},[_vm._v(_vm._s(_vm.$t("modules.domain.create_new_customer_link"))+" ")])]},proxy:true}],null,true),model:{value:(_vm.customerName),callback:function ($$v) {_vm.customerName=$$v},expression:"customerName"}})],1),_c('div'),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('general.url')),"type":_vm.getValidationErrors && _vm.getValidationErrors.url
                  ? 'is-danger'
                  : '',"message":_vm.getValidationErrors && _vm.getValidationErrors.url
                  ? _vm.getValidationErrors.url
                  : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(_vm.$t('placeholder.url')),"required":""},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.domain.contact_email')),"type":_vm.getValidationErrors && _vm.getValidationErrors.contact_email
                  ? 'is-danger'
                  : '',"message":_vm.getValidationErrors && _vm.getValidationErrors.contact_email
                  ? _vm.getValidationErrors.contact_email
                  : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(_vm.$t('placeholder.email')),"required":""},model:{value:(_vm.contactEmail),callback:function ($$v) {_vm.contactEmail=$$v},expression:"contactEmail"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.domain.contact_name')),"type":_vm.getValidationErrors && _vm.getValidationErrors.contact_name
                  ? 'is-danger'
                  : '',"message":_vm.getValidationErrors && _vm.getValidationErrors.contact_name
                  ? _vm.getValidationErrors.contact_name
                  : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(_vm.$t('placeholder.name')),"required":""},model:{value:(_vm.contactName),callback:function ($$v) {_vm.contactName=$$v},expression:"contactName"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String().capitalize(String(_vm.$t('general.responsible_user'))),"type":_vm.getValidationErrors && _vm.getValidationErrors.responsible_user_id
                  ? 'is-danger'
                  : '',"message":_vm.getValidationErrors && _vm.getValidationErrors.responsible_user_id
                  ? _vm.getValidationErrors.responsible_user_id
                  : ''}},[_c('b-select',{attrs:{"expanded":""},model:{value:(_vm.responsibleUserId),callback:function ($$v) {_vm.responsibleUserId=$$v},expression:"responsibleUserId"}},[_c('option',{domProps:{"value":null}},[_vm._v(" "+_vm._s(String( _vm.$t( "modules.domain.responsible_user_not_selected_option" ) ))+" ")]),_vm._l((_vm.getUserList),function(user,userIdx){return _c('option',{key:userIdx,domProps:{"value":user.id}},[_vm._v(" "+_vm._s(user.name)+" <"+_vm._s(user.email)+"> ")])})],2)],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.domain.responsible_name_label')),"type":_vm.getValidationErrors && _vm.getValidationErrors.responsible_name
                  ? 'is-danger'
                  : '',"message":_vm.getValidationErrors && _vm.getValidationErrors.responsible_name
                  ? _vm.getValidationErrors.responsible_name
                  : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(_vm.$t('placeholder.name')),"disabled":_vm.responsibleUserId,"required":""},model:{value:(_vm.responsibleName),callback:function ($$v) {_vm.responsibleName=$$v},expression:"responsibleName"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String(_vm.$t('modules.domain.webpage_description_label')),"type":_vm.getValidationErrors && _vm.getValidationErrors.webpage_description
                  ? 'is-danger'
                  : '',"message":_vm.getValidationErrors && _vm.getValidationErrors.webpage_description
                  ? _vm.getValidationErrors.webpage_description
                  : ''}},[_c('b-input',{attrs:{"type":"text","icon":"fas fa-copyright","placeholder":String(_vm.$t('modules.domain.webpage_description_placeholder')),"required":""},model:{value:(_vm.webpageDescription),callback:function ($$v) {_vm.webpageDescription=$$v},expression:"webpageDescription"}})],1),_c('b-field',{attrs:{"horizontal":formSettings.horizontal,"label":String().capitalize(_vm.$t('modules.domain.auto_renewal')),"type":_vm.getValidationErrors && _vm.getValidationErrors.auto_renewal
                  ? 'is-danger'
                  : '',"message":_vm.getValidationErrors && _vm.getValidationErrors.auto_renewal
                  ? _vm.getValidationErrors.auto_renewal
                  : ''}},[_c('b-switch',{attrs:{"required":""},model:{value:(_vm.autoRenewal),callback:function ($$v) {_vm.autoRenewal=$$v},expression:"autoRenewal"}})],1)],1)]}}])})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }